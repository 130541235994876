import React, {MouseEventHandler} from "react";
import { QueryClient, useQueryClient } from "react-query";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import axios, {AxiosResponse} from "axios";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Box} from '@mui/material';
import { useAuth } from '../auth/auth.hooks';
import { ThreatModelInfo } from "../threatmodel/threatmodel.types";
import { DetailsData } from "./detailsDialog.types";
import { ZoneForm } from "../zone/zone.component";
import { DeviceForm } from "../device/device.component";
import { DataflowForm } from "../dataflow/dataflowDetails/dataflowDetails.component";
import { ZoneInfo } from "../zone/zone.types";
import { ComponentDetails } from "../device/device.types";
import { DataflowInfo } from "../dataflow/dataflow.types";
import { EditButton } from "./detailComponents/detailsDialogComponents.component";

import { ModelForm } from "../threatmodel/add-model/addmodel.component";


export const withHooksHOC = (Component: React.ElementType) => function withHooks({detailsData, panelOpen, onPanelClose, loading = false, data = undefined}: DetailsViewProps): JSX.Element {
    const {accessToken, config} = useAuth();
    const queryClient = useQueryClient();
    const navigation = useNavigate();
    const location = useLocation();
    return <Component
            accessToken={accessToken}
            config={config}
            queryClient={queryClient}
            detailsData={detailsData}
            panelOpen={panelOpen}
            onPanelClose={onPanelClose}
            loading={loading}
            data={data}
            navigation={navigation}
            location={location}
            />;
};

interface DetailsViewProps {
    detailsData: DetailsData;
    panelOpen: boolean;
    onPanelClose?: () => void;
    loading?: boolean;
    data?: ZoneInfo | ComponentDetails | DataflowInfo | ThreatModelInfo;
}

interface IHooksHOCProps {
    accessToken: string;
    config: Record<string, unknown>;
    queryClient: QueryClient;
    detailsData: DetailsData;
    panelOpen: boolean;
    onPanelClose: () => void;
    loading: boolean;
    data?: ZoneInfo | ComponentDetails | DataflowInfo | ThreatModelInfo;
    navigation: NavigateFunction;
    location: Location;
}

interface DetailsDialogState {
    posting: boolean;
    editing: boolean;
    status: string;
    validName: boolean;
    nameHelperText: string;
    newItemID: string;
}

interface CreateButtonProps {
    onClick: MouseEventHandler<HTMLButtonElement>;
    disabled: boolean;
}


export function CreateButton({onClick, disabled}: CreateButtonProps): JSX.Element | null {
    return(
        <Button
            sx={{ margin: 1 }}
            style={{
                color: "#ffffff",
                backgroundColor: !disabled ? "#0f334a" : "#99b7c4" }}
            variant="text"
            disableRipple
            onClick={onClick}
            disabled={disabled}>
            Save
        </Button>
    );
}


class DetailsDialog extends React.Component<IHooksHOCProps, DetailsDialogState> {

    constructor(props: IHooksHOCProps) {
        super(props);
        this.state = {
            posting: false,
            editing: props.detailsData.addMode,
            status: props.detailsData.addMode ? "editing" : "view",
            validName: false,
            nameHelperText: "Name is required",
            newItemID: "",
        }
        this.DialogContentChooser = this.DialogContentChooser.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.DisplayStatusMessage = this.DisplayStatusMessage.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEditMode = this.handleEditMode.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.checkNameAvailibility = this.checkNameAvailibility.bind(this);
    }

    // Display a status message on the page based on upload status
    DialogContentChooser = (): JSX.Element => {
        const {detailsData} = this.props;
        const {data} = this.props;
        const {editing} = this.state;
        const zone = data as ZoneInfo
        const device = data as ComponentDetails
        const dataflow = data as DataflowInfo

        const model = data as ThreatModelInfo
        const {validName} = this.state;
        const {nameHelperText} = this.state;

        const {pathname} = window.location;
        const parts = pathname.split("/");
        const modelId = +parts[3];

        switch (detailsData.formName) {
            case "zone-form":
                return (
                    <ZoneForm editing={editing} data={zone}/>
                );
            case "device-form":
            return (
                <DeviceForm
                editing={editing}
                data={device}
                modelId={modelId}
                zoneId={detailsData.zoneId !== undefined ? detailsData.zoneId : 0}
                />
            );
            case "dataflow-form":
            return (
                <DataflowForm
                editing={editing}
                data={dataflow}
                modelId={modelId}
                inbound={detailsData.inbound !== undefined ? detailsData.inbound : false}
                deviceId={detailsData.deviceId !== undefined ? detailsData.deviceId : 0}/>
            );
            case "model-form":
            return (
                <ModelForm
                editing={editing}
                data={model}
                nameChecker={this.checkNameAvailibility}
                isValidName={validName}
                nameHelperText={nameHelperText}/>
            );
            default:
                return (
                    <Alert style={{display: "none"}} sx={{mb: 2}}>No content found</Alert>
                );
        }
    }

    handleClose = (): void  => {
        const {onPanelClose} = this.props;
        const {detailsData} = this.props;
        const {newItemID} = this.state;
        const {navigation} = this.props;
        const {location} = this.props;

        if (newItemID !== "" && detailsData.formName === "model-form") {

            const newPath = location.pathname.replace("add" , newItemID);
            navigation({pathname: newPath})
        }
        onPanelClose();
        this.setState({
            posting: false,
            editing: false,
            status: "editing",
        })
    };


    handleCancel = (): void  => {
        const {detailsData} = this.props;
        if (detailsData.addMode) {
            this.handleClose();
        }
        else {
            this.setState({
                posting: false,
                editing: false,
                status: "viewing",
            })
        }

    };

    handleEditMode = (): void  => {
        const {editing} = this.state;
        if (!editing) {
            this.setState({
                editing: true,
                status: "editing",
            })
        }
        else {
            this.handleSubmit();
        }
    };

    // Display a status message on the page based on upload status
    DisplayStatusMessage = (loading: boolean): JSX.Element => {
        if (loading) {
            return (
                <Alert severity="info" sx={{mb: 2}}>Loading data...</Alert>
            );
        }
        const {status} = this.state;
        const {detailsData} = this.props;
        switch (status) {
            case "posting":
                return (
                    <Alert severity="info" sx={{mb: 2}}>{detailsData.addMode ? "Creating a new..." : "Updating item..."}</Alert>
                );
            case "deleting":
                return (
                    <Alert severity="info" sx={{mb: 2}}>Deleting item...</Alert>
                );
            case "deletingFailed":
            return (
                <Alert severity="info" sx={{mb: 2}}>Deleting failed</Alert>
            );
            case "deletingSuccess":
            return (
                <Alert severity="info" sx={{mb: 2}}>Deleted succesfully</Alert>
            );
            case "success":
                return (
                    <Alert severity="success" sx={{mb: 2}}>{detailsData.addMode ? "Created successfully" : "Updated successfully"}</Alert>
                );
            case "failed":
                return (
                    <Alert severity="error" sx={{mb: 2}}>Failed to create new item</Alert>
                );
            default:
                return (
                    <Alert style={{display: "none"}} sx={{mb: 2}}>Hidden</Alert>
                );
        }
    }

    handleSubmit = (): void => {
        const {detailsData} = this.props;
        const form = document.getElementById(detailsData.formName) as HTMLFormElement;
        if(form && form.checkValidity()){ // use can also use e.target.reportValidity
            const {accessToken} = this.props;
            const {config} = this.props;
            const {pathname} = window.location;
            const parts = pathname.split("/");
            const productId = parts[2];
            const modelId = parts[3];

            const conf = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${accessToken}`
                },
                params: {
                    model_id: modelId,
                    product_id: productId,
                }
            }
            const data = new FormData();
            Array.prototype.forEach.call(form.elements, (element) => {
                data.append(element.name, element.value);
              })


            const callback = (response: AxiosResponse): void => {
                if (response.status === 200) {
                    const {queryClient} = this.props;
                    queryClient.invalidateQueries('architecture');
                    queryClient.invalidateQueries('device');
                    queryClient.invalidateQueries('threatmodel');

                    this.setState({
                        status: "success",
                        newItemID: response.data.id,
                    })
                    if (detailsData.mode === "page") {
                        this.setState({
                            posting: false,
                            editing: false,
                            status: "editing",
                        })
                    }

                } else {
                    this.setState({
                        status: "failed"
                    })
                }
            }
            this.setState({
                posting: true,
                status: "posting"
            })
            axios.post(`${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/${detailsData.path}`, data, conf)
                .then((response) => {
                    callback(response);
                }).catch(error => {
                    if(error.response) {
                        this.setState({
                            status: "failed"
                        })
                    }
            })
        }
    }

    handleDelete = (): void => {
        const {data} = this.props;
        const {detailsData} = this.props;
        const {accessToken} = this.props;
        const {config} = this.props;
        const conf = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${accessToken}`
            },
            params: {
                id: data !== undefined ? data.id : 0,
            }
        }

        const callback = (response: AxiosResponse): void => {
            if (response.status === 200) {
                this.setState({
                    status: "deletingSuccess"
                })
                const {queryClient} = this.props;
                queryClient.invalidateQueries('architecture');
                queryClient.invalidateQueries('device');

            } else {
                this.setState({
                    status: "deletingFailed"
                })
            }
        }
        this.setState({
            posting: true,
            status: "deleting"
        })
        axios.delete(`${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/${detailsData.deletePath}`, conf)
            .then((response) => {
                callback(response);
            }).catch(error => {
                if(error.response) {
                    this.setState({
                        status: "deletingFailed"
                    })
                }
        })
    }


    checkNameAvailibility = (newName: string): void  => {
        if (newName === "") {
            this.setState({
                validName: false,
                nameHelperText: "Name is required"
            })
        }
        else {
            const {accessToken} = this.props;
            const {config} = this.props;

            const {pathname} = window.location;
            const parts = pathname.split("/");
            const productId = parts[2];

            const data = {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                params: {
                    product_id: productId,
                    name: newName
                }
            }
            const callback = (response: AxiosResponse): void => {
                if (response.status === 200) {
                    if (response.data) {
                        this.setState({
                            validName: true,
                            nameHelperText: "Name is available"
                        })
                    }
                    else {
                        this.setState({
                            validName: false,
                            nameHelperText: "Name is already in use"
                        })
                    }

                } else {
                    this.setState({
                        validName: false,
                        nameHelperText: "Name is already in use"
                    })
                }
            }
            axios.get(`${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/threatmodels/name_available`, data)
                .then((response) => {
                    callback(response);
                }).catch(error => {
                    if(!error.response) {
                        this.setState({
                            validName: false,
                            nameHelperText: "Undefined error while checking name"
                        })
                    }
                    else {
                        this.setState({
                            validName: false,
                            nameHelperText: "Error while checking name"
                        })
                    }
            })
        }

    }


    Actions(): JSX.Element{
        const {posting} = this.state;
        const {editing} = this.state;
        const {validName} = this.state;
        const {detailsData} = this.props;
        const {data} = this.props;
        return(
            <Box className="detailsButtonBar">
                <Box>
                    {(data && data.canDelete)&&
                        <Button
                        style={{ color: "#ffffff", backgroundColor: "#ff0000"}}
                        onClick={this.handleDelete}>Delete</Button>
                    }
                </Box>
                <Box>
                    {!posting && editing &&
                        <Button
                        style={{ color: "#ffffff", backgroundColor: "#0f334a"}}
                        onClick={this.handleCancel}>Cancel</Button>
                    }
                    {!posting && editing &&
                        <CreateButton
                        onClick={this.handleSubmit}
                        disabled={detailsData.addMode && detailsData.formName === "model-form" ? !validName : false}/>
                    }
                    {data && detailsData.canEdit && !editing &&
                        <EditButton onClick={this.handleEditMode}
                        updating={posting} enabled/>
                    }
                    {(posting || !editing )&& detailsData.mode !== "page" &&
                        <Button
                        style={{ color: "#ffffff", backgroundColor: "#0f334a"}}
                        onClick={this.handleClose}>Close</Button>
                    }
                </Box>
            </Box>
        );
    }


   // Render the page
    render(): JSX.Element {
    const {posting} = this.state;
    const {editing} = this.state;
    const {detailsData} = this.props;
    const {panelOpen} = this.props;
    const {loading} = this.props;

    if (detailsData.addMode && !editing) {
        this.setState({
            editing: true,
        })
    }

        return (
            <Box>
                {detailsData.mode === "dialog" &&
                    <Dialog fullWidth
                    maxWidth="sm"
                    open={panelOpen}
                    onClose={this.handleClose}>
                <DialogTitle>{detailsData.title}</DialogTitle>
                {this.DisplayStatusMessage(loading)}
                {!posting && !loading &&
                        <DialogContent>
                            {this.DialogContentChooser()}
                        </DialogContent>
                    }
                <DialogActions>
                    {this.Actions()}
                </DialogActions>
                </Dialog>
                }
                {detailsData.mode === "page" &&
                <Box>
                    {this.DisplayStatusMessage(loading)}
                    {this.DialogContentChooser()}
                    {this.Actions()}
                </Box>
                }

            </Box>
        );
}

}

export default withHooksHOC(DetailsDialog);
